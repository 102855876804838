// import '../scss/theme.scss';

document.addEventListener('DOMContentLoaded', () => {

	// Get all "navbar-burger" elements
	const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

	// Check if there are any navbar burgers
	if ($navbarBurgers.length > 0) {

		// Add a click event on each of them
		$navbarBurgers.forEach( el => {
			el.addEventListener('click', () => {

				// Get the target from the "data-target" attribute
				const target = el.dataset.target;
				const $target = document.getElementById(target);

				// Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
				el.classList.toggle('is-active');
				$target.classList.toggle('is-active');
			});
		});

		var ddItems = document.querySelectorAll('.has-dropdown');
		if (ddItems.length > 0) {
			ddItems.forEach(function (element, index) {
				var item =  element.querySelector(".navbar-link");
				var mmenu = document.createElement("a");
				mmenu.className = "dd-wrap";
				mmenu.innerHTML = '<div class="card-arrow chevron--down"><svg width="30" height="30" version="1.1" viewbox="0 0 100 50" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><g class="chevron__container"><line class="chevron__line1" x1="0" y1="50" x2="52" y2="50" /><line class="chevron__line2" x1="100" y1="50" x2="48" y2="50" /></g></svg></div>';
				item.appendChild(mmenu);
			});
		}
	}

});
//smooth scrolling
jQuery($ => {
	$(document).ready(function(){
		$(document).on('click', '.dd-wrap', function(ev){
			ev.preventDefault();
			$(this).children('.card-arrow').toggleClass('chevron--down chevron--up');
			$(this).closest('.has-dropdown').children('.navbar-dropdown').toggleClass('dd-open');
		});
	});
	// The speed of the scroll in milliseconds
	const speed = 1000;
	$('a[href*="#"]')
		.filter((i, a) => a.getAttribute('href').startsWith('#') || a.href.startsWith(`${location.href}#`))
		.unbind('click.smoothScroll')
		.bind('click.smoothScroll', event => {
			const targetId = event.currentTarget.getAttribute('href').split('#')[1];
			const targetElement = document.getElementById(targetId);

			if (targetElement) {
				event.preventDefault();
				//history.pushState(null, null, null);
				var windowWidth = jQuery( window ).width();
				var scrollHelp;
				if(windowWidth < 992) {
					scrollHelp = 115;
				} else {
					scrollHelp = 160;
				}
				$('html, body').animate({ scrollTop: $(targetElement).offset().top - scrollHelp }, speed);
			}
		});
	//jump to section to activate
	$('.jts-anchor-links').click(function() {
		$(this).toggleClass('jts-active');
	});

	//jump to section to replace text with selected anchor text
	$('.jts-anchor-links-container a').click(function() {
		var currectSection = jQuery(this).text();
		$('.jts-anchor-links-toggle-label').html(currectSection);
	});

	$('.find-dropdown-anchor-links').click(function() {
		$(this).toggleClass('find-dropdown-active');
	});
});

//add class on scroll
window.onscroll = function() {
	var currentScrollPos = window.pageYOffset;
	if (currentScrollPos > 150) {
		document.querySelector('header.site-header').classList.add('fade-in');
	} else {
		document.querySelector('header.site-header').classList.remove('fade-in');
	}
}

//set height of header as padding on main tag for all interior pages
var headerHeight = document.querySelector('header.site-header').outerHeight;
var mainBody = document.querySelector('#main');
mainBody.style.paddingTop = headerHeight+'px';



//loop through content to pull all h2s and add anchors to h2
if (document.getElementById("toc-list") != null) {
	var titleArray = [];
	var h2Titles = document.querySelectorAll('.entry__content h2');
	if (h2Titles.length > 0) {
		h2Titles.forEach(function (element, index) {
			element.setAttribute('id', 'toc' + index);
			titleArray.push(element.textContent);
		});
		titleArray.forEach(function (element, index) {
			var li = document.createElement("li");
			li.innerHTML = '<a href="#toc' + index + '">' + element + '</a>';
			document.getElementById("toc-list").appendChild(li);
		});
	} else {
		document.querySelector(".toc-nav").style.display = "none";
	}
	// document.querySelector('.toc-nav').onclick = e => {
	// 	var menu = document.querySelector('.toc-nav')
	// 	menu.classList.toggle('is-active');
	// }
}

var link = document.getElementById("back-to-top");
var amountScrolled = 250;

window.addEventListener('scroll', function(e) {
	if ( window.pageYOffset > amountScrolled ) {
		link.classList.add('show');
	} else {
		link.className = 'back-to-top';
	}
});
//scroll to top
link.addEventListener('click', function(e) {
	e.preventDefault();

	var distance = 0 - window.pageYOffset;
	var increments = distance/(1000/16);
	function animateScroll() {
		window.scrollBy(0, increments);
		if (window.pageYOffset <= document.body.offsetTop) {
			clearInterval(runAnimation);
		}
	}
	// Loop the animation function
	var runAnimation = setInterval(animateScroll, 100);
});
